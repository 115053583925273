import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'

import useDeleteTMDialog, { State, Action } from './useDeleteTMDialog'
import S3File from '../../lib/S3File'

type ContainerProps = {
  onClose: (updated: boolean) => void
  selectedFiles: S3File[]
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { onClose, selectedFiles, state, dispatch, handleSubmit } = props

  return (
    <Dialog open={true} onClose={() => onClose(false)}>
      {state.status === 'initial' && (
        <>
          <DialogContent style={{ color: 'red' }}>
            <p>選択した以下のファイルを削除します。</p>
            <p>削除したファイルは復元することができません。</p>
            <ul data-testid="selected-files-list">
              {selectedFiles.map((file) => {
                return <li key={file.Key}>{file.Key.replace(/^assets\//, '')}</li>
              })}
            </ul>
            <p>ファイルを削除してもよろしいですか？</p>
            <p>
              削除する場合は、以下のテキストボックスに<strong>delete</strong>
              と入力し、OKボタンを押してください。
            </p>
            <TextField
              value={state.keyword}
              onChange={(e) => {
                dispatch({
                  type: 'SET_KEYWORD',
                  payload: { keyword: e.currentTarget.value },
                })
              }}
              inputProps={{
                'data-testid': 'input-keyword',
                placeholder: 'delete',
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)}>キャンセル</Button>
            <Button
              color="secondary"
              variant="contained"
              disabled={state.keyword !== 'delete'}
              onClick={handleSubmit}
              data-testid="submit-button"
            >
              {state.loading ? <CircularProgress size="1.5rem" /> : <>OK</>}
            </Button>
          </DialogActions>
        </>
      )}
      {state.status === 'completed' && (
        <>
          <DialogContent>
            <p>ファイルの削除が完了しました。</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(true)} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </>
      )}
      {state.status === 'error' && (
        <>
          <DialogContent>
            <p style={{ color: 'red' }}>{state.message.text}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(true)} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { selectedFiles } = props
  const { state, dispatch, handleSubmit } = useDeleteTMDialog(selectedFiles)

  return <Component state={state} dispatch={dispatch} handleSubmit={handleSubmit} {...props} />
}

Container.displayName = 'DeleteTMDialog'
export default Container
