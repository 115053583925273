import React, { useEffect, useReducer } from 'react'
import queryString from 'query-string'

import S3File from '../../lib/S3File'

export type State = {
  selectedRows: S3File[]
  acceptedFiles: File[]
  showUploadMenu: boolean
  showDeleteMenu: boolean
  showUploadTMDialog: boolean
  showDeleteTMDialog: boolean
  toggleRefresh: boolean
  query: string
}

export type Action =
  | { type: 'SET_SELECTED_ROWS'; payload: { selectedRows: S3File[] } }
  | { type: 'SET_ACCEPTED_FILES'; payload: { acceptedFiles: File[] } }
  | { type: 'SET_SHOW_UPLOAD_MENU'; payload: { show: boolean } }
  | { type: 'SET_SHOW_DELETE_MENU'; payload: { show: boolean } }
  | { type: 'SET_SHOW_UPLOAD_TM_DIALOG'; payload: { show: boolean } }
  | { type: 'SET_SHOW_DELETE_TM_DIALOG'; payload: { show: boolean } }
  | { type: 'TOGGLE_REFRESH' }
  | { type: 'SET_QUERY'; payload: { query: string } }

export const initialState: State = {
  selectedRows: [],
  acceptedFiles: [],
  showUploadMenu: false,
  showDeleteMenu: false,
  showUploadTMDialog: false,
  showDeleteTMDialog: false,
  toggleRefresh: true,
  query: '',
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SELECTED_ROWS': {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
      }
    }
    case 'SET_ACCEPTED_FILES': {
      return {
        ...state,
        acceptedFiles: action.payload.acceptedFiles,
      }
    }
    case 'SET_SHOW_UPLOAD_MENU': {
      return {
        ...state,
        showUploadMenu: action.payload.show,
      }
    }
    case 'SET_SHOW_DELETE_MENU': {
      return {
        ...state,
        showDeleteMenu: action.payload.show,
      }
    }
    case 'SET_SHOW_UPLOAD_TM_DIALOG': {
      return {
        ...state,
        showUploadTMDialog: action.payload.show,
      }
    }
    case 'SET_SHOW_DELETE_TM_DIALOG': {
      return {
        ...state,
        showDeleteTMDialog: action.payload.show,
      }
    }
    case 'TOGGLE_REFRESH': {
      return {
        ...state,
        toggleRefresh: !state.toggleRefresh,
      }
    }
    case 'SET_QUERY': {
      return {
        ...state,
        query: action.payload.query,
      }
    }
    default: {
      return state
    }
  }
}

export const useMasterTMListPage = (
  qs: string
): {
  state: State
  dispatch: React.Dispatch<Action>
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  useEffect(() => {
    const query = queryString.parse(qs)
    dispatch({
      type: 'SET_QUERY',
      payload: { query: typeof query['q'] === 'string' ? decodeURI(query['q']) : '' },
    })
  }, [qs])

  return { state, dispatch }
}
