import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import path from 'path'

import { useAxiosInstance } from '../hooks/useAxiosInstance'

const AutoDownloadPage: React.FC = () => {
  const axios = useAxiosInstance()

  const { filePathURIEncoded } = useParams<{ filePathURIEncoded: string }>()
  const [isError, setIsError] = useState(false)
  const [fileExists, setFileExists] = useState(true)

  useEffect(() => {
    const objectKeyOrg = decodeURIComponent(filePathURIEncoded)
    const filename = path.basename(objectKeyOrg)
    axios
      .post<{
        presignedUrl: string
        exists: boolean
        contentType: string
      }>('generateDownloadUrl', {
        objectKey: objectKeyOrg,
      })
      .then((res) => {
        if (res.data.exists === true) {
          setFileExists(true)
          const linkEl = document.createElement('a')
          linkEl.href = res.data.presignedUrl
          linkEl.setAttribute('download', filename)
          document.body.appendChild(linkEl)
          linkEl.click()
          document.body.removeChild(linkEl)
        } else {
          setFileExists(false)
        }
      })
      .catch(() => {
        setIsError(true)
      })
  }, [axios, filePathURIEncoded])

  return (
    <>
      {fileExists && !isError && (
        <div>
          <p>ファイルをダウンロードします。</p>
        </div>
      )}
      {!fileExists && !isError && (
        <div className="error">
          <p>ファイルが存在しないため、ダウンロードできません。</p>
        </div>
      )}
      {isError && (
        <div className="error">
          <p>エラーが発生したため、ダウンロードできません。</p>
          <p>
            再度試しても、このエラーメッセージが表示される場合は、システム管理者にお問い合わせください。
          </p>
        </div>
      )}
    </>
  )
}

export default AutoDownloadPage
