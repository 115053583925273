import React, { createContext } from 'react'
import { HashRouter, Redirect, Route } from 'react-router-dom'
import Amplify from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'

import config from './config'
import Navbar from './components/Navbar'
import AutoDownloadPage from './pages/AutoDownloadPage'
import DownloadPage from './pages/DownloadPage'
import MasterTMListPage from './pages/MasterTMListPage'
import UploadPage from './pages/UploadPage'
import './App.css'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'

Amplify.configure({
  Auth: config[config.STAGE].Auth,
})

export const CognitoUserContext = createContext(Amplify.Auth.user)

const App: React.FC = () => {
  const signOut = () => {
    Amplify.Auth.signOut()
    window.location.reload()
  }

  return (
    <HashRouter>
      <div className="App">
        <CognitoUserContext.Provider value={Amplify.Auth.user}>
          <Navbar signOut={signOut} />
          <Route exact path="/">
            <Redirect to="/upload_search" />
          </Route>
          <Route exact path="/upload_search">
            <UploadPage
              caption="< TM 検索 > \n
              TM 検索を行いたい原文ファイル (docx、xlsx、pptx) をアップロードしてください。"
              folderName="dropzone/search/"
              batchAPI="runSearchBatch"
              acceptSuffix={['.xlsx', '.pptx', '.docx']}
            />
          </Route>

          <Route exact path="/download_search">
            <DownloadPage folderName="result/search/" isConfirm={false} />
          </Route>

          <Route exact path="/upload_confirm">
            <UploadPage
              caption="< マスターTM登録 >\n
              マスターTMデータベースに登録したいバイリンガルファイル（sdlxliff）、または、翻訳メモリ（sdltm、tmx）をアップロードしてください。\n
              なお、Microsoft Word文書（docx）をあわせてアップロードした場合は、変更履歴をバイリンガルファイル（sdlxliff）に反映した上で、\n
              マスターTMデータベースに登録します。"
              folderName="dropzone/confirm/"
              batchAPI="runConfirmBatch"
              acceptSuffix={['.docx', '.sdlxliff', '.sdltm', '.tmx']}
            />
          </Route>

          <Route exact path="/download_confirm">
            <DownloadPage folderName="result/confirm/" isConfirm={true} />
          </Route>

          <Route exact path="/download/:filePathURIEncoded">
            <AutoDownloadPage />
          </Route>

          <Route exact path="/master_list">
            <MasterTMListPage />
          </Route>
        </CognitoUserContext.Provider>
      </div>
    </HashRouter>
  )
}

export default withAuthenticator(App)
