import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { ICellRendererParams } from 'ag-grid-community'

import EditFileNameDialog from '../EditFileNameDialog'

const DownloadButtonRenderer = (params: ICellRendererParams): JSX.Element => {
  const [showEditFileNameDialog, setShowEditFileNameDialog] = useState(false)

  return params.data ? (
    <>
      <Button onClick={() => setShowEditFileNameDialog(true)}>
        <EditIcon />
      </Button>
      {showEditFileNameDialog && (
        <EditFileNameDialog
          file={params.data}
          onClose={(updated: boolean) => {
            setShowEditFileNameDialog(false)
            if (updated) {
              window.location.reload()
            }
          }}
        />
      )}
    </>
  ) : (
    <></>
  )
}

export default DownloadButtonRenderer
