import { useEffect, useState } from 'react'
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  RowSelectedEvent,
} from 'ag-grid-community'
import queryString from 'query-string'

import { useAxiosInstance } from '../../hooks/useAxiosInstance'
import useColumnDefs from './useColumnDefs'
import DownloadButtonRenderer from './DownloadButtonRenderer'
import EditFileNameButtonRenderer from './EditFileNameButtonRenderer'
import FileNameRenderer from './FileNameRenderer'
import S3File from '../../lib/S3File'

const useMasterTMList = ({
  updateSelection,
  qs,
  supportedFormats,
  toggleRefresh,
}: {
  updateSelection: (selected: S3File[]) => void
  qs: string
  supportedFormats?: string[]
  toggleRefresh?: boolean
}): { gridApi: GridApi | undefined; gridOptions: GridOptions } => {
  const axios = useAxiosInstance()

  const { columnDefs, defaultColDef } = useColumnDefs()

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  useEffect(() => {
    if (gridApi) {
      const updateData = (): void => {
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            gridApi.hideOverlay()
            const query = queryString.parse(qs)
            const lastRowNode = gridApi.getRowNode((params.startRow - 1).toString())
            setTimeout(async () => {
              const response = await axios.post<S3File[]>('listObjects', {
                folder: `assets/${query['q'] || ''}`,
                startAfter: lastRowNode?.data.Key || 'assets/',
              })

              const regexp = supportedFormats
                ? new RegExp(`(${supportedFormats.join('|').replace(/\./g, '\\.')})$`)
                : new RegExp('')

              const files = response.data
                .filter((file) => !file.Key.endsWith('/'))
                .filter((file) => {
                  if (supportedFormats && supportedFormats.length > 0) {
                    return file.Key.match(regexp)
                  }
                  return true
                })
              const rowsThisPage = files.slice(0, 100)

              const lastRow = files.length < 100 ? params.startRow + files.length : -1

              if (params.startRow === 0 && files.length === 0) {
                gridApi.showNoRowsOverlay()
              }

              params.successCallback(rowsThisPage, lastRow)
            }, 500)
          },
        }
        gridApi.setDatasource(dataSource)
      }

      updateData()
    }
  }, [gridApi, toggleRefresh, qs, axios, supportedFormats])

  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
  }

  const onRowSelected = (params: RowSelectedEvent): void => {
    const selected = params.api.getSelectedRows()
    updateSelection(selected)
  }

  const gridOptions: GridOptions = {
    cacheOverflowSize: 5,
    enableCellTextSelection: true,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    frameworkComponents: {
      DownloadButtonRenderer,
      EditFileNameButtonRenderer,
      FileNameRenderer,
    },
    infiniteInitialRowCount: 0,
    maxBlocksInCache: 10,
    maxConcurrentDatasourceRequests: 1,
    onGridReady: onGridReady,
    onRowSelected: onRowSelected,
    paginationPageSize: 100,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowMultiSelectWithClick: true,
    rowSelection: 'multiple',
    overlayNoRowsTemplate:
      '<p>該当するファイルが見つかりませんでした。<br>検索する文字列を再度確認してください。<br>（[ジョブの名前]/[ファイル名]で、前方一致検索されます。）</p>',
  }

  return { gridApi, gridOptions }
}

export default useMasterTMList
