import React, { useContext } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'

// import config from '../../config'
import useUploadTMDialog, { State, Action } from './useUploadTMDialog'
import { CognitoUserContext } from '../../App'

type ContainerProps = {
  files: File[]
  onClose: (updated: boolean) => void
  supportedFormats?: string[]
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { files, onClose, state, dispatch, handleSubmit, supportedFormats } = props

  const regexp = supportedFormats
    ? new RegExp(`(${supportedFormats.join('|').replace(/\./g, '\\.')})$`)
    : new RegExp('')

  const acceptedFiles = files.filter((file) => file.name.match(regexp))
  const rejectedFiles = files.filter((file) => !file.name.match(regexp))

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      {state.status === 'initial' && (
        <>
          <DialogContent>
            <p>アップロードするファイルのジョブ名を入力してください。</p>
            <form>
              <TextField
                label="ジョブの名前"
                onChange={(e) => {
                  dispatch({ type: 'SET_FORM_VALUE', payload: { jobName: e.currentTarget.value } })
                }}
                value={state.formData.jobName}
                style={{ width: '100%' }}
                inputProps={{
                  'data-testid': 'input-job-name',
                }}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)}>キャンセル</Button>
            <Button
              color="primary"
              onClick={handleSubmit}
              data-testid="ok-button"
              disabled={state.formData.jobName.length === 0}
            >
              OK
            </Button>
          </DialogActions>
        </>
      )}
      {state.status === 'confirm' && (
        <>
          <DialogContent>
            {acceptedFiles.length > 0 && (
              <>
                <p data-testid="upload-message">以下のとおり、ファイルをアップロードします。</p>
                {state.duplicatedFiles.length > 0 && (
                  <>
                    <p style={{ color: 'red' }}>
                      なお、&lt;上書き&gt;と表示されているファイルは同一ファイル名のファイルがすでに存在するため、既存のファイルを上書きします。
                    </p>
                    <p style={{ color: 'red' }}>上書きされたファイルを復元することはできません。</p>
                  </>
                )}
                <ul data-testid="accepted-files-list">
                  {acceptedFiles.map((file) => {
                    const objectKey = `${state.formData.jobName
                      .replace(/^[/]+/, '')
                      .replace(/[/]+$/, '')}/${file.name}`
                    return (
                      <li key={file.name}>
                        {objectKey}
                        {state.duplicatedFiles.includes(objectKey) && (
                          <span style={{ color: 'red' }}> &lt;上書き&gt;</span>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
            {rejectedFiles.length > 0 && (
              <>
                <p>
                  以下のファイルは、サポートされていないファイル形式のため、アップロードできません。
                </p>
                <ul data-testid="rejected-files-list">
                  {rejectedFiles.map((file) => {
                    return <li key={file.name}>{file.name}</li>
                  })}
                </ul>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <span style={state.message.isError ? { color: 'red' } : undefined}>
              {state.message.text}
            </span>
            <Button disabled={state.loading} onClick={() => onClose(false)}>
              キャンセル
            </Button>
            {acceptedFiles.length > 0 && (
              <Button
                color="primary"
                disabled={state.loading}
                onClick={handleSubmit}
                data-testid="submit-button"
              >
                {state.loading ? <CircularProgress size="1.5rem" /> : <>OK</>}
              </Button>
            )}
          </DialogActions>
        </>
      )}
      {state.status === 'completed' && (
        <>
          <DialogContent>
            <p>ファイルの追加が完了しました。</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(true)}>閉じる</Button>
          </DialogActions>
        </>
      )}
      {state.status === 'error' && (
        <>
          <DialogContent>
            <p style={{ color: 'red' }}>{state.message.text}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(true)}>閉じる</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const cognitoUser = useContext(CognitoUserContext)
  const { files, supportedFormats } = props
  const { state, dispatch, handleSubmit } = useUploadTMDialog({
    files,
    supportedFormats,
    cognitoUser,
  })

  return <Component state={state} dispatch={dispatch} handleSubmit={handleSubmit} {...props} />
}

Container.displayName = 'UploadTMDialog'
export default Container
