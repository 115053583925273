import { useCallback, useReducer } from 'react'

import { useAxiosInstance } from '../../hooks/useAxiosInstance'

type FormData = {
  jobName: string
}

export type State = {
  status: 'initial' | 'confirm' | 'completed' | 'error'
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
  duplicatedFiles: string[]
}

export type Action =
  | { type: 'SET_STATUS'; payload: { status: 'initial' | 'confirm' | 'completed' | 'error' } }
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }
  | { type: 'SET_DUPLICATED_FILES'; payload: { files: string[] } }

export const initialState: State = {
  status: 'initial',
  loading: false,
  message: {
    isError: false,
    text: '',
  },
  formData: {
    jobName: '',
  },
  duplicatedFiles: [],
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload.status,
      }
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false,
        },
      }
    case 'SET_FORM_VALUE':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      }
    case 'SET_DUPLICATED_FILES':
      return {
        ...state,
        duplicatedFiles: action.payload.files,
      }
    default:
      return state
  }
}

const useUploadTMDialog = ({
  files,
  supportedFormats,
  cognitoUser,
}: {
  files: File[]
  supportedFormats?: string[]
  cognitoUser: any
}): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const axios = useAxiosInstance()

  const [state, dispatch] = useReducer(stateReducer, initialState)

  const regexp = supportedFormats
    ? new RegExp(`(${supportedFormats.join('|').replace(/\./g, '\\.')})$`)
    : new RegExp('')

  const acceptedFiles = files.filter((file) => file.name.match(regexp))

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
    try {
      if (state.status === 'initial') {
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const response = await axios.post('listObjects', {
              folder: `assets/${state.formData.jobName.replace(/^[/]+/, '').replace(/[/]+$/, '')}/${
                file.name
              }`,
            })
            if (response.data.length > 0) {
              dispatch({
                type: 'SET_DUPLICATED_FILES',
                payload: {
                  files: [...state.duplicatedFiles, response.data[0].Key.replace(/^assets\//, '')],
                },
              })
            }
          })
        )
        dispatch({ type: 'SET_STATUS', payload: { status: 'confirm' } })
      }
      if (state.status === 'confirm') {
        const uploadFiles = async () => {
          await Promise.all(
            acceptedFiles.map(async (file) => {
              const uploadUrlResponse = await axios.post<{
                presignedUrl: string
                objectKey: string
                contentType: string
              }>('generateUploadUrl', {
                folder: `assets/${state.formData.jobName
                  .replace(/^[/]+/, '')
                  .replace(/[/]+$/, '')}/`,
                filename: file.name,
                emailTo: cognitoUser.attributes.email,
              })
              const { presignedUrl, contentType } = uploadUrlResponse.data
              return fetch(presignedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                  'Content-Type': contentType,
                },
              })
            })
          )
          dispatch({ type: 'SET_STATUS', payload: { status: 'completed' } })
        }
        if (state.duplicatedFiles.length > 0) {
          if (window.confirm('ファイルのアップロード、および、追加と上書きを開始します。')) {
            await uploadFiles()
          }
        } else {
          await uploadFiles()
        }
      }
    } catch (error) {
      dispatch({ type: 'SET_STATUS', payload: { status: 'error' } })
      let message
      if (error.response) {
        const errorInfo = error.response.data
        message = errorInfo.message
      } else {
        message =
          error.message === 'Network Error'
            ? 'ネットワークに接続されていないため、ファイルをアップロードできません。'
            : error.message
      }
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
      dispatch({ type: 'SET_MESSAGE', payload: { isError: true, message } })
    }
    dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
  }, [
    state.status,
    state.formData.jobName,
    state.duplicatedFiles,
    acceptedFiles,
    axios,
    cognitoUser.attributes.email,
  ])

  return { state, dispatch, handleSubmit }
}

export default useUploadTMDialog
