import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { AgGridReact } from 'ag-grid-react'

import useMasterTMList from './useMasterTMList'
import S3File from '../../lib/S3File'

type Props = RouteComponentProps & {
  updateSelection: (selected: S3File[]) => void
  supportedFormats?: string[]
  toggleRefresh?: boolean
}

const MasterTMList: React.FC<Props> = (props: Props) => {
  const { updateSelection, supportedFormats, toggleRefresh } = props
  const qs = props.location.search
  const { gridOptions } = useMasterTMList({
    updateSelection,
    qs,
    supportedFormats,
    toggleRefresh,
  })

  return (
    <div className="ag-theme-material" style={{ height: '100%', width: '100%' }}>
      <AgGridReact gridOptions={gridOptions} />
    </div>
  )
}

export default withRouter(MasterTMList)
