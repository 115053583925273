import React, { useState } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { ICellRendererParams } from 'ag-grid-community'

import { useAxiosInstance } from '../../hooks/useAxiosInstance'

const DownloadButtonRenderer = (params: ICellRendererParams): JSX.Element => {
  const axios = useAxiosInstance()

  const [loading, setLoading] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const download = async () => {
    setLoading(true)
    const objectKey = params.data.Key

    try {
      const response = await axios.post('generateDownloadUrl', {
        objectKey,
      })

      const { exists, presignedUrl } = response.data
      if (exists) {
        const linkEl = document.createElement('a')
        linkEl.href = presignedUrl
        linkEl.setAttribute('download', '')
        document.body.appendChild(linkEl)
        linkEl.click()
        document.body.removeChild(linkEl)
      } else {
        setShowErrorDialog(true)
      }
    } catch (error) {
      setShowErrorDialog(true)
    }
    setLoading(false)
  }

  return params.data ? (
    <>
      <Button onClick={download}>{loading ? <CircularProgress /> : <SaveAltIcon />}</Button>
      {showErrorDialog && (
        <Dialog open={true} data-testid="download-error-dialog">
          <DialogContent>ファイルをダウンロードできませんでした。</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setShowErrorDialog(false)}>
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  ) : (
    <></>
  )
}

export default DownloadButtonRenderer
