import { Button } from 'aws-amplify-react'
import React, { useCallback, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'

import UploadDialog from '../components/UploadDialog'

type Props = {
  caption: string
  folderName: string
  batchAPI: string
  acceptSuffix: string[]
}

const UploadPage: React.FC<Props> = (props: Props) => {
  const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setAcceptedFiles(acceptedFiles)
    setFileRejections(fileRejections)
    setShowConfirmUploadDialog(true)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.acceptSuffix,
  })

  const onFileUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedFiles([])
    setFileRejections([])

    if (event.target.files) {
      const files = Array.from(event.target.files)

      const accepted: File[] = []
      const rejections: FileRejection[] = []

      files.forEach((file) => {
        if (file.name.match(new RegExp(`(${props.acceptSuffix.join('|')})$`))) {
          accepted.push(file)
        } else {
          rejections.push({ file } as FileRejection)
        }
      })

      setAcceptedFiles(accepted)
      setFileRejections(rejections)
    }

    setShowConfirmUploadDialog(true)
    event.target.value = '' // どこかでクリアしておかないと同一ファイルの選択でonChangeが発火しなくなる
  }

  const getCustomProps = () => {
    return { webkitdirectory: '' }
  }
  return (
    <>
      <div className="dropzone" {...getRootProps({ onClick: (e) => e.stopPropagation() })}>
        <input {...getInputProps()} {...getCustomProps()} />
        {props.caption.split('\\n').map((str, i) => {
          return <p key={`${props.batchAPI}_${i}`}>{str}</p>
        })}
      </div>
      <div className="uploadButtons">
        <input
          id="fileUpload"
          type="file"
          multiple
          accept={props.acceptSuffix.join(',')}
          className="inputFileHide"
          onChange={onFileUploadChange}
        />
        <Button variant="contained" component="label" className="inputFileButton">
          ファイルをアップロード
        </Button>
        <input
          id="folderUpload"
          type="file"
          multiple
          accept={props.acceptSuffix.join(',')}
          className="inputFileHide"
          onChange={onFileUploadChange}
          {...getCustomProps()}
        />
        <Button variant="contained" component="label" className="inputFolderButton">
          ディレクトリをアップロード
        </Button>
      </div>
      {showConfirmUploadDialog && (
        <UploadDialog
          close={(): void => setShowConfirmUploadDialog(false)}
          acceptedFiles={acceptedFiles}
          fileRejections={fileRejections}
          folderName={props.folderName}
          batchAPI={props.batchAPI}
          showLangPairs={props.batchAPI === 'runSearchBatch'}
        />
      )}
    </>
  )
}

export default UploadPage
