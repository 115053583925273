import React from 'react'
import { CircularProgress, Tooltip, withStyles } from '@material-ui/core'
import { ICellRendererParams } from 'ag-grid-community'

const MyTooltip = withStyles({ tooltip: { fontSize: '12px' } })(Tooltip)

const FileNameRenderer = (params: ICellRendererParams): JSX.Element => {
  const filename = params.value?.replace(/^assets\//, '') || ''

  return params.data ? (
    <MyTooltip title={filename} placement="bottom-start">
      <span data-tooltip={filename}>{filename}</span>
    </MyTooltip>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress size="1.2rem" />
    </div>
  )
}

export default FileNameRenderer
