import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import MasterTMList from '../../components/MasterTMList'

import { useMasterTMListPage } from './useMasterTMListPage'
import Toolbar from './Toolbar'
import DeleteTMDialog from '../../components/DeleteTMDialog'
import UploadTMDialog from '../../components/UploadTMDialog'
import S3File from '../../lib/S3File'
import config from '../../config'

const MasterTMListPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const qs = props.location.search
  const { state, dispatch } = useMasterTMListPage(qs)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
      <Toolbar state={state} dispatch={dispatch} />
      <MasterTMList
        updateSelection={(selected: S3File[]) => {
          dispatch({ type: 'SET_SELECTED_ROWS', payload: { selectedRows: selected } })
        }}
        supportedFormats={config.S3Browser.supportedFormats.list}
        toggleRefresh={state.toggleRefresh}
      />
      {state.showUploadTMDialog && (
        <UploadTMDialog
          files={state.acceptedFiles}
          supportedFormats={config.S3Browser.supportedFormats.upload}
          onClose={(updated: boolean) => {
            dispatch({ type: 'SET_SHOW_UPLOAD_TM_DIALOG', payload: { show: false } })
            if (updated) {
              window.location.reload()
            }
          }}
        />
      )}
      {state.showDeleteTMDialog && (
        <DeleteTMDialog
          onClose={(updated: boolean) => {
            dispatch({ type: 'SET_SHOW_DELETE_TM_DIALOG', payload: { show: false } })
            if (updated) {
              window.location.reload()
            }
          }}
          selectedFiles={state.selectedRows}
        />
      )}
    </div>
  )
}

export default withRouter(MasterTMListPage)
