import { useCallback, useEffect, useReducer } from 'react'

import { useAxiosInstance } from '../../hooks/useAxiosInstance'
import S3File from '../../lib/S3File'

type FormData = {
  filename: string
}

export type State = {
  status: 'initial' | 'confirm' | 'completed' | 'error'
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  formData: FormData
}

export type Action =
  | { type: 'SET_STATUS'; payload: { status: 'initial' | 'confirm' | 'completed' | 'error' } }
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_FORM_VALUE'; payload: Partial<FormData> }

export const initialState: State = {
  status: 'initial',
  loading: false,
  message: {
    isError: false,
    text: '',
  },
  formData: {
    filename: '',
  },
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload.status,
      }
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false,
        },
      }
    case 'SET_FORM_VALUE':
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

const useEditFileNameDialog = (
  file: S3File
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
  validate: (filename: string) => void
} => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const axios = useAxiosInstance()

  const ext = file.Key.match(/\.[^.]+$/)

  const handleSubmit = useCallback(async () => {
    const oldFilename = file.Key
    const newFilename = `assets/${state.formData.filename}${ext}`

    if (oldFilename === newFilename) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: '変更前と異なる名前を入力してください。', isError: true },
      })
      return
    }

    const rename = async () => {
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
      await axios.post('copyObject', {
        source: encodeURI(oldFilename),
        target: newFilename,
      })
      await axios.post('deleteObjects', {
        objectKeys: [oldFilename],
      })
      dispatch({
        type: 'SET_STATUS',
        payload: { status: 'completed' },
      })
    }

    try {
      if (state.status === 'initial') {
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })

        const listObjectsResponse = await axios.post('listObjects', {
          folder: newFilename,
        })
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
        if (listObjectsResponse.data.length > 0) {
          dispatch({ type: 'SET_STATUS', payload: { status: 'confirm' } })
        } else {
          await rename()
        }
      } else {
        if (window.confirm('ファイルの上書きとファイル名の変更を開始します。')) {
          await rename()
        }
      }
    } catch (error) {
      dispatch({ type: 'SET_STATUS', payload: { status: 'error' } })
      let message
      if (error.response) {
        const errorInfo = error.response.data
        message = errorInfo.message
      } else {
        message =
          error.message === 'Network Error'
            ? 'ネットワークに接続されていないため、ファイル名を変更できません。'
            : error.message
      }
      dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
      dispatch({ type: 'SET_MESSAGE', payload: { isError: true, message } })
    }
  }, [axios, ext, file.Key, state.formData.filename, state.status])

  const validate = (filename: string): void => {
    if (Buffer.byteLength('assets/' + filename + ext) > 1024) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          isError: true,
          message: '変更後のジョブの名前/ファイル名が長すぎるため、ファイル名を変更できません。',
        },
      })
    } else {
      dispatch({ type: 'SET_MESSAGE', payload: { isError: false, message: '' } })
    }
  }

  useEffect(() => {
    const filename = file.Key.replace(/^assets\//, '').replace(/\.[^.]+$/, '')
    dispatch({
      type: 'SET_FORM_VALUE',
      payload: {
        filename,
      },
    })
  }, [file])

  return { state, dispatch, handleSubmit, validate }
}

export default useEditFileNameDialog
