import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'

import useEditFileNameDialog, { State, Action } from './useEditFileNameDialog'
import S3File from '../../lib/S3File'

type ContainerProps = {
  file: S3File
  onClose: (updated: boolean) => void
}

type Props = ContainerProps & {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => void
  validate: (filename: string) => void
}

export const Component: React.FC<Props> = (props: Props) => {
  const { file, onClose, state, dispatch, handleSubmit, validate } = props

  const oldFilename = file.Key.replace(/^assets\//, '')
  const ext = file.Key.match(/\.[^.]+$/)

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      {state.status === 'initial' && (
        <DialogContent>
          <p>変更後の[ジョブの名前 / ファイル名]を入力してください。</p>
          <div style={{ color: 'rgba(0,0,0,0.38)' }}>
            <span style={{ fontSize: 'small' }}>変更前の[ジョブの名前 / ファイル名]</span>
            <br />
            <span data-testid="current-file-name">{oldFilename}</span>
          </div>
          <form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                marginTop: '1rem',
              }}
            >
              <TextField
                label="変更後の[ジョブの名前 / ファイル名]"
                error={state.message.isError}
                onChange={(e) => {
                  validate(e.currentTarget.value)
                  dispatch({
                    type: 'SET_FORM_VALUE',
                    payload: {
                      filename: e.currentTarget.value,
                    },
                  })
                }}
                helperText={state.message.text}
                value={state.formData.filename}
                style={{ width: '100%' }}
                inputProps={{
                  'data-testid': 'input-file-name',
                }}
              />
              <span>{ext}</span>
            </div>
          </form>
        </DialogContent>
      )}
      {state.status === 'confirm' && (
        <DialogContent style={{ color: 'red' }}>
          <p>変更後のファイル名と同名のファイルが既に存在します。</p>
          <p>上書きされたファイルを復元することはできません。</p>
          <ul>
            <li>
              {state.formData.filename}
              {ext}
            </li>
          </ul>
          <p>ファイルを上書きしてよろしいですか？</p>
        </DialogContent>
      )}
      {(state.status === 'initial' || state.status === 'confirm') && (
        <DialogActions>
          <Button disabled={state.loading} onClick={() => onClose(false)}>
            キャンセル
          </Button>
          <Button
            color="primary"
            disabled={
              state.loading || state.formData.filename.length === 0 || state.message.isError
            }
            onClick={handleSubmit}
            data-testid="submit-button"
          >
            {state.loading ? <CircularProgress size="1.5rem" /> : <>OK</>}
          </Button>
        </DialogActions>
      )}
      {state.status === 'completed' && (
        <>
          <DialogContent>ファイル名の変更が完了しました。</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onClose(true)}>
              閉じる
            </Button>
          </DialogActions>
        </>
      )}
      {state.status === 'error' && (
        <>
          <DialogContent>
            <p style={{ color: 'red' }}>{state.message.text}</p>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => onClose(true)}>
              閉じる
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { file } = props
  const { state, dispatch, handleSubmit, validate } = useEditFileNameDialog(file)

  return (
    <Component
      state={state}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      validate={validate}
      {...props}
    />
  )
}

Container.displayName = 'EditFileNameDialog'
export default Container
