import { createContext, useContext } from 'react'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { Auth } from 'aws-amplify'
import config from '../config'

// useAxiosInstance.ts
const axios = Axios.create({
  baseURL: `${config[config.STAGE].endpoint}/api/v1`,
})

const isFile = (data: any): data is File =>
  data.size !== undefined && data.name !== undefined && data.type !== undefined

const isUploadToS3 = (config: AxiosRequestConfig): boolean =>
  config.method === 'put' && !!config.url && /\.s3\./.test(config.url) && isFile(config.data)

export const axiosRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession()
  // s3へのPUTは Authorizationヘッダーを受け付けないらしい
  // https://stackoverflow.com/a/53031218
  if (isUploadToS3(config)) {
    delete config.headers.Authorization
    return config
  }

  if (session) {
    config.headers.Authorization = session.getIdToken().getJwtToken()
  }
  return config
}
axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))

const AxiosInstanceContext = createContext<AxiosInstance>(axios)

export const AxiosInstanceProvider = AxiosInstanceContext.Provider

export const AciosInstanceConsumer = AxiosInstanceContext.Consumer

export const useAxiosInstance = (): AxiosInstance => {
  return useContext(AxiosInstanceContext)
}
