import React, { useCallback, useState, useEffect } from 'react'
import config from '../config'
import Amplify, { Auth } from 'aws-amplify'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'

import { useAxiosInstance } from '../hooks/useAxiosInstance'

interface Props {
  folderName: string
  isConfirm: boolean
}

type S3File = {
  ETag: string
  Key: string
  LastModified: string
  Size: number
  StorageClass: string
}

Amplify.configure({
  Auth: config[config.STAGE].Auth,
})

const DownloadPage: React.FC<Props> = (props: Props) => {
  const axios = useAxiosInstance()

  const [prefix, setPrefix] = useState('')
  const [downloadList, setDownloadlist] = useState<S3File[]>([])
  const [isError, setError] = useState(false)
  const [hasFileList, setHasFileList] = useState(false)

  const ResultList = (props: { results: any[] }) => {
    if (props.results.length !== 0) {
      const list = props.results
      // 更新日時の降順で並び替え
      list.sort((a, b) => new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime())
      const listItems = list.map((result, index) => (
        <TableRow key={index}>
          <TableCell align={'left'} padding={'default'}>
            {result.Key.replace(prefix, '')}
          </TableCell>
          <TableCell align={'center'} padding={'default'}>
            {new Date(result.LastModified).toLocaleString('ja-JP')}
          </TableCell>
          <TableCell align={'center'} padding={'default'}>
            <Link onClick={() => getFile(result.Key)}>ダウンロード</Link>
          </TableCell>
        </TableRow>
      ))
      return (
        <TableBody>
          <TableRow>
            <TableCell align={'left'} padding={'default'}>
              名称
            </TableCell>
            <TableCell align={'center'} padding={'default'}>
              最終更新日時
            </TableCell>
            <TableCell align={'center'} padding={'default'}>
              操作
            </TableCell>
          </TableRow>
          {listItems}
        </TableBody>
      )
    } else {
      return (
        <tbody>
          <tr>
            <td>ファイルがありません</td>
          </tr>
        </tbody>
      )
    }
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((authUser) => {
      const userName = authUser.getUsername()
      const folderName = props.folderName
      setPrefix(folderName + userName + '/')
      axios
        .post('listObjects', {
          folder: folderName + userName + '/',
        })
        .then((res) => {
          setDownloadlist(res.data)
          setHasFileList(true)
        })
    })
  }, [axios, props.folderName])

  const getFile = useCallback(
    async (fileName: string) => {
      setError(false)
      axios
        .post<{
          presignedUrl: string
          exists: boolean
          contentType: string
        }>('generateDownloadUrl', {
          objectKey: fileName,
        })
        .then((res) => {
          if (res.data.exists === true) {
            const linkEl = document.createElement('a')
            linkEl.href = res.data.presignedUrl
            linkEl.setAttribute('download', fileName)
            document.body.appendChild(linkEl)
            linkEl.click()
            document.body.removeChild(linkEl)
          } else {
            setError(true)
          }
        })
        .catch(() => {
          setError(true)
        })
    },
    [axios]
  )

  return (
    <>
      {isError ? (
        <div className="error">
          <br />
          ダウンロードできなかったファイルがあります。
        </div>
      ) : (
        false
      )}
      <Table className="donwloads">
        {hasFileList ? (
          <ResultList results={downloadList} />
        ) : (
          <TableBody>
            <TableRow>
              <TableCell>loading....</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      {props.isConfirm ? (
        <div className="confirmButton">
          <Button
            variant="contained"
            color="primary"
            onClick={() => getFile('references/マスターTM登録一覧.csv')}
          >
            マスターTM登録一覧をダウンロード
          </Button>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default DownloadPage
