import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import dateformat from 'dateformat'

const useColumnDefs = (): { columnDefs: ColDef[]; defaultColDef: ColDef } => {
  const defaultColDef: ColDef = {
    lockVisible: true,
    minWidth: 50,
    resizable: true,
    sortable: false,
    width: 100,
  }

  const dateTimeFormatter = (params: ValueFormatterParams) => {
    return params.value ? dateformat(new Date(Date.parse(params.value)), 'yyyy/mm/dd HH:MM:ss') : ''
  }

  const columnDefs: ColDef[] = [
    {
      colId: '_id',
      headerName: '',
      checkboxSelection: true,
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
      width: 50,
    },
    {
      colId: 'Key',
      headerName: 'ファイル名',
      field: 'Key',
      cellRenderer: 'FileNameRenderer',
    },
    {
      colId: 'editFileName',
      cellRenderer: 'EditFileNameButtonRenderer',
      maxWidth: 100,
      resizable: false,
    },
    {
      colId: 'download',
      headerName: 'ダウンロード',
      cellRenderer: 'DownloadButtonRenderer',
      maxWidth: 150,
      resizable: false,
    },
    {
      colId: 'LastModified',
      field: 'LastModified',
      headerName: '更新日時',
      valueFormatter: dateTimeFormatter,
    },
  ]
  return { columnDefs, defaultColDef }
}

export default useColumnDefs
