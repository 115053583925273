import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AppBar, Button, Tab, Tabs, Toolbar, Typography } from '@material-ui/core'
import Amplify, { Auth } from 'aws-amplify'
import config from '../config'

interface Props extends RouteComponentProps {
  signOut: () => void
}

Amplify.configure({
  Auth: config[config.STAGE].Auth,
})

const Navbar: React.FC<Props> = (props: Props) => {
  const [userName, setUserName] = useState('')
  const selectedTab = (() => {
    switch (props.location.pathname) {
      case '/upload_search':
        return 1
      case '/download_search':
        return 2
      case '/upload_confirm':
        return 3
      case '/download_confirm':
        return 4
      case '/master_list':
        return 5
      default:
        return 0
    }
  })()
  Auth.currentAuthenticatedUser().then((authUser) =>
    setUserName('（' + authUser.getUsername() + '）')
  )

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">翻訳メモリ管理システム</Typography>
        <Tabs
          style={{ flexGrow: 1, marginLeft: '1.5rem' }}
          selectionFollowsFocus
          value={selectedTab}
        >
          <Tab label="" value={0} style={{ display: 'none' }} />
          <Tab label="TM 検索" onClick={() => props.history.push('/upload_search')} value={1} />
          <Tab
            label="出力 (TM 検索)"
            onClick={() => props.history.push('/download_search')}
            value={2}
          />
          <Tab
            label="マスター TM 登録"
            onClick={() => props.history.push('/upload_confirm')}
            value={3}
          />
          <Tab
            label="出力 (マスター TM 登録)"
            onClick={() => props.history.push('/download_confirm')}
            value={4}
          />
          <Tab
            label="マスターTM一覧"
            onClick={(): void => props.history.push('/master_list')}
            value={5}
          />
        </Tabs>
        <Button color="inherit" onClick={() => props.signOut()}>
          ログアウト
        </Button>
        {userName}
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Navbar)
