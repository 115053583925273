import React, { useContext, useEffect, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Auth } from 'aws-amplify'

import { CognitoUserContext } from '../App'
import config from '../config'
import { useAxiosInstance } from '../hooks/useAxiosInstance'

type Props = {
  close: () => void
  acceptedFiles: File[]
  fileRejections: FileRejection[]
  folderName: string
  batchAPI: string
  showLangPairs: boolean
}

type JobNameError = {
  error: boolean
  message?: string
}

const UploadDialog: React.FC<Props> = (props: Props) => {
  const axios = useAxiosInstance()

  const { acceptedFiles } = props
  const cognitoUser = useContext(CognitoUserContext)

  const [status, setStatus] = useState<'initial' | 'uploading' | 'completed' | 'error'>('initial')
  const [jobNameError, setJobNameError] = useState<JobNameError>({ error: false, message: '' })
  const [jobName, setJobName] = useState('')
  const [languagePair, setLanguagePair] = React.useState<'en-US,ja-JP' | 'ja-JP,en-US'>(
    'ja-JP,en-US'
  )

  useEffect(() => {
    if (jobName !== '') {
      setJobNameError({ error: false, message: '' })
    }
  }, [jobName])

  const close = () => {
    setJobNameError({ error: false, message: '' })
    props.close()
  }

  const languagePairs = [
    {
      value: 'en-US,ja-JP',
      label: '英語→日本語',
    },
    {
      value: 'ja-JP,en-US',
      label: '日本語→英語',
    },
  ]

  const jobNameChange = (event: any) => {
    setJobName(event.target.value)
  }

  const languagePairChange = (event: any) => {
    setLanguagePair(event.target.value)
  }

  const onClickOK = async () => {
    if (jobName === '') {
      setJobNameError({ error: true, message: 'ジョブの名前を入力してください。' })
    } else if (jobName.match(/\/{2,}/)) {
      setJobNameError({
        error: true,
        message: 'ジョブの名前には、2つ以上連続してスラッシュ（/）を入力することはできません',
      })
    } else {
      setStatus('uploading')

      const userAttributes = await Auth.userAttributes(cognitoUser)
      const email = userAttributes.find((value) => value.getName() === 'email')

      try {
        const folderRes = await axios.post<{ folderName: string }>(
          'generateUniqueFolderName',
          props.showLangPairs
            ? {
                suffix: `⁞${jobName.replace(/(^\/)|(\/$)/g, '')}⁞${languagePair}`,
              }
            : { suffix: `⁞${jobName.replace(/(^\/)|(\/$)/g, '')}` }
        )
        await Promise.all(
          props.acceptedFiles.map(async (file: File) => {
            const donwloadUrlResponse = await axios.post<{
              presignedUrl: string
              objectKey: string
              contentType: string
            }>('generateUploadUrl', {
              folder: `${props.folderName}${cognitoUser.getUsername()}/${
                folderRes.data.folderName
              }/`,
              filename: file.name,
              emailTo: email?.getValue(),
            })

            const presignedUrl = donwloadUrlResponse.data.presignedUrl
            const contentType = donwloadUrlResponse.data.contentType

            // axiosだとEdgeでS3へのアップロードができなかったのでfetch APIを使用
            return fetch(presignedUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': contentType,
              },
            }).then((response) => {
              if (!response.ok) {
                throw response
              }
            })
          })
        )
        await axios.post(`${config[config.STAGE].endpointSearch}/api/v1/${props.batchAPI}`, {
          folder: `${props.folderName}${cognitoUser.getUsername()}/${folderRes.data.folderName}/`,
        })
        setStatus('completed')
      } catch (error) {
        setStatus('error')
        if (config.STAGE === 'development') {
          console.error(error.body)
        }
      }
    }
  }

  return (
    <Dialog open={true} maxWidth="md" fullWidth={true}>
      <DialogContent>
        {jobNameError.error && (
          <Alert severity="error">
            <AlertTitle>入力エラー</AlertTitle>
            {jobNameError && <p>{jobNameError.message}</p>}
          </Alert>
        )}
        {status === 'initial' && (
          <>
            {props.acceptedFiles.length > 0 && (
              <>
                <p>以下のファイルをアップロードします。</p>
                <ul>
                  {props.acceptedFiles.map((file, i) => (
                    <li key={i}>{file.name}</li>
                  ))}
                </ul>
              </>
            )}
            {props.fileRejections.length > 0 && (
              <>
                <p>
                  以下のファイルは、サポートされていないファイル形式のため、アップロードできません。
                </p>
                <ul>
                  {props.fileRejections.map((fileRejection, i) => (
                    <li key={i}>{fileRejection.file.name}</li>
                  ))}
                </ul>
              </>
            )}
            {props.acceptedFiles.length > 0 && (
              <>
                <TextField
                  id="jobName"
                  label="ジョブの名前"
                  fullWidth={true}
                  onChange={jobNameChange}
                  error={jobNameError.error}
                />
                {props.showLangPairs && (
                  <>
                    <InputLabel>言語</InputLabel>
                    <Select
                      id="languagePair"
                      label="言語"
                      defaultValue="ja-JP,en-US"
                      onChange={languagePairChange}
                    >
                      {languagePairs.map((langPair) => (
                        <MenuItem key={langPair.value} value={langPair.value}>
                          {langPair.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>原文言語・訳文言語の対を選択してください。</FormHelperText>
                  </>
                )}
              </>
            )}
          </>
        )}
        {status === 'uploading' && (
          <>
            <p>アップロード中...</p>
            <CircularProgress />
          </>
        )}
        {status === 'completed' && <p>アップロードが完了しました。</p>}
        {status === 'error' && <p>アップロードできなかったファイルがあります。</p>}
      </DialogContent>
      <DialogActions>
        {status === 'initial' && (
          <>
            <Button onClick={close}>キャンセル</Button>
            {acceptedFiles.length > 0 && (
              <Button
                onClick={onClickOK}
                color="primary"
                disabled={jobNameError.error || jobName === ''}
              >
                OK
              </Button>
            )}
          </>
        )}
        {(status === 'completed' || status === 'error') && (
          <Button onClick={close} color="primary">
            閉じる
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default UploadDialog
