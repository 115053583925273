import { useCallback, useReducer } from 'react'

import S3File from '../../lib/S3File'
import { useAxiosInstance } from '../../hooks/useAxiosInstance'

export type State = {
  status: 'initial' | 'completed' | 'error'
  loading: boolean
  message: {
    isError: boolean
    text: string
  }
  keyword: string
}

export type Action =
  | { type: 'SET_STATUS'; payload: { status: 'initial' | 'completed' | 'error' } }
  | { type: 'SET_IS_LOADING'; payload: { loading: boolean } }
  | { type: 'SET_MESSAGE'; payload: { message: string; isError?: boolean } }
  | { type: 'SET_KEYWORD'; payload: { keyword: string } }

export const initialState: State = {
  status: 'initial',
  loading: false,
  message: {
    isError: false,
    text: '',
  },
  keyword: '',
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload.status,
      }
    case 'SET_IS_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      }
    case 'SET_MESSAGE':
      return {
        ...state,
        message: {
          text: action.payload.message,
          isError: action.payload.isError ?? false,
        },
      }
    case 'SET_KEYWORD':
      return {
        ...state,
        keyword: action.payload.keyword,
      }
    default:
      return state
  }
}

const useDeleteTMDialog = (
  selectedFiles: S3File[]
): {
  state: State
  dispatch: React.Dispatch<Action>
  handleSubmit: () => Promise<void>
} => {
  const axios = useAxiosInstance()
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const handleSubmit = useCallback(async () => {
    if (
      window.confirm(
        'ファイルの削除を開始してもよろしいですか？　この操作を取り消すことはできません。'
      )
    ) {
      try {
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: true } })
        await axios.post('deleteObjects', {
          objectKeys: selectedFiles.map((file) => file.Key),
        })
        dispatch({ type: 'SET_STATUS', payload: { status: 'completed' } })
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
      } catch (error) {
        dispatch({ type: 'SET_STATUS', payload: { status: 'error' } })
        let message
        if (error.response) {
          const errorInfo = error.response.data
          message = errorInfo.message
        } else {
          message =
            error.message === 'Network Error'
              ? 'ネットワークに接続されていないため、ファイルを削除できません。'
              : error.message
        }
        dispatch({ type: 'SET_IS_LOADING', payload: { loading: false } })
        dispatch({ type: 'SET_MESSAGE', payload: { isError: true, message } })
      }
    }
  }, [axios, selectedFiles])

  return { state, dispatch, handleSubmit }
}

export default useDeleteTMDialog
