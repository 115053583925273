import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Menu, MenuItem, TextField, Tooltip, withStyles } from '@material-ui/core'

import { State, Action } from './useMasterTMListPage'

type ContainerProps = RouteComponentProps & {
  state: State
  dispatch: React.Dispatch<Action>
}

type Props = ContainerProps & {
  onChangeFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
  search: () => void
}

const MyTooltip = withStyles({ tooltip: { fontSize: '12px' } })(Tooltip)

const Component: React.FC<Props> = (props: Props) => {
  const qs = props.location.search
  const { state, dispatch, onChangeFiles, search } = props

  const getCustomProps = () => {
    return { webkitdirectory: '' }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1rem',
      }}
    >
      <div>
        <Button
          id="upload-button"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch({
              type: 'SET_SHOW_UPLOAD_MENU',
              payload: { show: true },
            })
          }}
        >
          ファイルの追加
        </Button>
        {state.showUploadMenu && (
          <Menu
            anchorEl={document.getElementById('upload-button')}
            open={true}
            onClose={() => {
              dispatch({
                type: 'SET_SHOW_UPLOAD_MENU',
                payload: { show: false },
              })
            }}
            style={{ top: '36px' }}
          >
            <MenuItem>
              <input
                id="upload-file"
                type="file"
                multiple
                onChange={onChangeFiles}
                className="inputFileHide"
              />
              <label htmlFor="upload-file" style={{ cursor: 'pointer', width: '100%' }}>
                ファイルを指定
              </label>
            </MenuItem>
            <MenuItem>
              <input
                id="upload-folder"
                type="file"
                multiple
                onChange={onChangeFiles}
                style={{ display: 'none' }}
                {...getCustomProps()}
              />
              <label htmlFor="upload-folder" style={{ cursor: 'pointer', width: '100%' }}>
                ディレクトリを指定
              </label>
            </MenuItem>
          </Menu>
        )}
        <Button
          id="delete-button"
          variant="contained"
          color="secondary"
          disabled={state.selectedRows.length === 0}
          onClick={() => {
            dispatch({
              type: 'SET_SHOW_DELETE_MENU',
              payload: { show: true },
            })
          }}
          style={{ marginLeft: '0.5rem' }}
        >
          ファイルの削除
        </Button>
        {state.showDeleteMenu && (
          <Menu
            anchorEl={document.getElementById('delete-button')}
            open={true}
            onClose={() => {
              dispatch({
                type: 'SET_SHOW_DELETE_MENU',
                payload: { show: false },
              })
            }}
            style={{ top: '36px' }}
          >
            <MenuItem
              onClick={() => {
                dispatch({
                  type: 'SET_SHOW_DELETE_MENU',
                  payload: { show: false },
                })
                dispatch({
                  type: 'SET_SHOW_DELETE_TM_DIALOG',
                  payload: { show: true },
                })
              }}
            >
              指定したファイルの削除
            </MenuItem>
          </Menu>
        )}
      </div>
      <div>
        <form onSubmit={search}>
          <MyTooltip
            title={
              '検索したいジョブの名前とファイル名を、"/"でつないで入力してください。（前方一致）'
            }
            arrow
          >
            <TextField
              value={state.query}
              onChange={(e) => {
                dispatch({
                  type: 'SET_QUERY',
                  payload: { query: e.currentTarget.value },
                })
              }}
              style={qs.length > 0 ? { backgroundColor: '#f4a460' } : undefined}
            />
          </MyTooltip>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '0.5rem' }}
            type="submit"
          >
            検索
          </Button>
          <Button
            variant="contained"
            onClick={() => props.history.push('/master_list')}
            style={{ marginLeft: '0.5rem' }}
            disabled={props.location.search.length === 0}
          >
            全件表示
          </Button>
        </form>
      </div>
    </div>
  )
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const { state, dispatch } = props

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_SHOW_UPLOAD_MENU', payload: { show: false } })

    if (event.target.files) {
      const files = Array.from(event.target.files)

      dispatch({ type: 'SET_ACCEPTED_FILES', payload: { acceptedFiles: files } })
      dispatch({ type: 'SET_SHOW_UPLOAD_TM_DIALOG', payload: { show: true } })
    }

    dispatch({
      type: 'SET_SHOW_UPLOAD_TM_DIALOG',
      payload: { show: true },
    })
    event.target.value = '' // どこかでクリアしておかないと同一ファイルの選択でonChangeが発火しなくなる
  }

  const search = () => {
    props.history.push(
      `/master_list${state.query.length > 0 ? '?q=' + encodeURIComponent(state.query) : ''}`
    )
  }

  return <Component onChangeFiles={onChangeFiles} search={search} {...props} />
}

Container.displayName = 'Toolbar'
export default withRouter(Container)
