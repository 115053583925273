export default {
  STAGE: process.env.REACT_APP_ENV || 'development',
  S3Browser: {
    supportedFormats: {
      upload: ['.tmx', '.sdltm', '.sdlxliff'],
      list: [],
    },
  },
  development: {
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
    endpoint: process.env.REACT_APP_ENDPOINT,
    endpointSearch: process.env.REACT_APP_ENDPOINT_SEARCH,
  },
  staging: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_kDaZoi5nL',
      userPoolWebClientId: '17n4tfkoef4khoh8gelmamc302',
    },
    endpoint: 'https://chugai-tm-api-stg.translation-dashboard.com',
    endpointSearch: 'https://chugai-tm-batch-api-stg.translation-dashboard.com',
  },
  production: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_O61zrh4nS',
      userPoolWebClientId: '680e3f6dclseocvi80iesd2k1',
    },
    endpoint: 'https://chugai-tm-api.translation-dashboard.com',
    endpointSearch: 'https://chugai-tm-batch-api.translation-dashboard.com',
  },
  test: {
    Auth: {
      identityPoolId: '',
      region: '',
      userPoolId: '',
      userPoolWebClientId: '',
    },
    endpoint: '',
    endpointSearch: '',
  },
}
